<template>
    <div>
        <!-- 医生管理 -->
        <router-view/>
    </div>
</template>

<script>
export default {
    
}
</script>

<style>

</style>